import { msalInstance, loginRequest } from "./../hooks/authConfig";
import { InteractionType } from "@azure/msal-browser";
import store from "@/store";

export function registerGuard(router) {
    router.beforeEach(async (to) => {
        if (to.meta.requiresAuth) {
            const request = {
                ...loginRequest,
                redirectStartPage: to.fullPath
            }
            const shouldProceed = await isAuthenticated(msalInstance, InteractionType.Redirect, request/* , to.meta.AutenticateGroup */ );
            return shouldProceed || '/failed';
        }

        return true;
    });
}

export async function isAuthenticated(instance, interactionType, loginRequest/* , AutenticateGroup */) {
    // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not  
    return instance.handleRedirectPromise().then(async () => {
        const accounts = instance.getAllAccounts();

        if (accounts.length > 0) {
            const userName = accounts[0].username

            await store.dispatch("user/setUser", {
                userId: userName,
                token: "result.token",
            });
//TODO: verificar origen de grupos
/*             if(AutenticateGroup){
                if(!accounts[0].idTokenClaims.groups){
                    return false;
                }
                if(!accounts[0].idTokenClaims.groups.includes(AutenticateGroup)){
                    return false;
                }
            } */


            return true;
        }

        // User is not signed in and attempting to access protected route. Sign them in.
        if (interactionType === InteractionType.Popup) {
            return instance.loginPopup(loginRequest).then(() => {
                return true;
            }).catch(() => {
                return false;
            })
        } else if (interactionType === InteractionType.Redirect) {
            return instance.loginRedirect(loginRequest).then(() => {
                return true;
            }).catch(() => {
                return false;
            });
        }

        return false;
    }).catch((e) => {
        console.log(e);
        return false;
    });
}