import { createStore } from "vuex";
import app from "./modules/app";
import travels from "./modules/travels";
import user from "./modules/user";

const store = createStore({
  modules: {
    app,
    travels,
    user,
  },
});

export default store;
