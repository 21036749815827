/**
 * CONSTANT for muttation in the store
 */
const types = {
  APP_SET_CONNECTION: "APP_SET_CONNECTION", // [APP] set connection status
  APP_SET_CONNECTION_API: "APP_SET_CONNECTION_API", // [APP] set Connection status
  APP_SET_WEBMAP: "APP_SET_webmap", // [APP] set webmap
  APP_SET_COLORS: "APP_SET_COLORS", // [APP] set colors
  UI_LOADING_HOME_START: "UI_LOADING_HOME_START", //[UI] set loading in home page start.
  UI_LOADING_HOME_FINISH: "UI_LOADING_HOME_FINISH", //[UI] set loading in home page finish.
  UI_LOADING_HISTORY_START: "UI_LOADING_HISTORY_START", //[UI] set loading in history page start.
  UI_LOADING_HISTORY_FINISH: "UI_LOADING_HISTORY_FINISH", //[UI] set loading in history page finish.
  USER_SET_USER: "USER_SET_USER", // [USER] set data for user
  USER_SET_USERID: "USER_SET_USERID", // [USER] set USERID for user
  USER_DEL_USER: "USER_DEL_USER", // [USER] remove user
  USER_SET_TOKEN: "USER_SET_TOKEN", // [USER] SET TOKEN
  TRAVEL_LOAD_DATA: "TRAVEL_LOAD_DATA", // [TRAVEL] load details actual travel
  TRAVEL_LOAD_DATA_TABLE: "TRAVEL_LOAD_DATA_TABLE", // [TRAVEL] load details actual travel for table
  TRAVEL_UPDATE_DATA: "TRAVEL_UPDATE_DATA", // [TRAVEL] update data for actual travel
  TRAVEL_VEHICLE_UPDATE_DATA: "TRAVEL_VEHICLE_UPDATE_DATA", // [TRAVEL] vehicle update data for actual travel
  TRAVEL_LOAD_HISTORY_DATA: "TRAVEL_LOAD_HISTORY_DATA", // [TRAVEL] load last travel data histories
  TRAVEL_UPDATE_HISTORY_DATA: "TRAVEL_UPDATE_HISTORY_DATA", // [TRAVEL] update next 5 last data travel
};

export default types;
