import types from "@/types";
//import { msalInstance } from "./../../hooks/authConfig";

/**
 * vuex module travel data.
 * @displayName Travels
 * @author Ricardo Portillo
 * @supplier Logicstudio
 * @version 0.0.1
 */
const travels = {
  namespaced: true,
  state() {
    return {
      travelId: null,
      data: [],
      loadData: false,
      loadDataHistory: false,
      dataIndexNext: 0,
      histories: [],
      TableTravels: []
    };
  },
  mutations: {
    /**
     * MUTATION FUNCTION: set data attribute
     * @method TRAVEL_LOAD_DATA
     * @param {object} travelId, data
     */
    [types.TRAVEL_LOAD_DATA](state, { travelId, data }) {
      state.travelId = travelId;
      state.data = data;
      state.dataIndexNext = data.length;
    },
    /**
     * MUTATION FUNCTION: set data attribute for table
     * @method TRAVEL_LOAD_DATA_TABLE
     * @param {object} TableTravels
     */
        [types.TRAVEL_LOAD_DATA_TABLE](state, { TableTravels = [] }) {
          state.TableTravels = TableTravels;
        },
    /**
     * MUTATION FUNCTION: add new data in attribute data-
     * @method TRAVEL_UPDATE_DATA
     * @param {object} state state attribute
     * @param {object} payload array object
     */
    [types.TRAVEL_UPDATE_DATA](state, payload) {
      state.data = [...state.data, ...payload];
      const newIndex = state.dataIndexNext + payload.length;
      if (newIndex > state.dataIndexNext) {
        state.dataIndexNext = newIndex;
      }
    },
    /**
     * MUTATION FUNCTION: set new vehicle data.
     * @method TRAVEL_VEHICLE_UPDATE_DATA
     * @param {object} state state attribute
     * @param {object} payload
     */
    [types.TRAVEL_VEHICLE_UPDATE_DATA](state, payload) {
      const index = state.data.findIndex((d) => d.vehicle.id == payload.id);
      if (index > -1) {
        state.data[index].vehicle.speed = payload.speed;
        state.data[index].vehicle.vehiclePlate = payload.vehiclePlate;
        state.data[index].vehicle.vehicleYear = payload.vehicleYear;
      }
    },
    /**
     * MUTATION FUNCTION:set history data
     * @method TRAVEL_LOAD_HISTORY_DATA
     * @param {object} state state attribute
     * @param {object} payload array object
     */
    [types.TRAVEL_LOAD_HISTORY_DATA](state, payload) {
      state.histories = payload;
    },
    /**
     * MUTATION FUNCTION:add new data in attribute history
     * @method TRAVEL_UPDATE_HISTORY_DATA
     * @param {object} state state attribute
     * @param {object} payload array object
     */
    [types.TRAVEL_UPDATE_HISTORY_DATA](state, payload) {
      state.histories = state.histories.concat(payload);
    },
    setLoadData(state) {
      state.loadData = !state.loadData;
    },
    setLoadDataHistory(state) {
      state.loadDataHistory = !state.loadDataHistory;
    },
  },
  actions: {
    /**
     * ACTION FUNCTION: call http request for list first data travels (20)
     * @method startLoad
     */
    async startLoad({ commit, rootState }) {
      commit("setLoadData");
      const myHeaders = new Headers();

      myHeaders.append("Authorization", rootState.user.token);
      myHeaders.append("X-API-KEY", process.env.VUE_APP_APIKEY);
      const apiUrl = process.env.VUE_APP_APIURL;

      const response = await fetch(
        `${apiUrl}/api/travels/${rootState.user.uid}`,
        {
          method: "GET",
          headers: myHeaders,
        }
      );
      const result = await response.json();
      commit(types.TRAVEL_LOAD_DATA, {
        travelId: 145124,
        data: result,
      });
      commit("setLoadData");
    },


    async loadMyData({ commit, rootState }) {

      commit("setLoadData");
      const myHeaders = new Headers();

      myHeaders.append("Authorization", rootState.user.token);
      myHeaders.append("X-API-KEY", process.env.VUE_APP_APIKEY);


      const apiUrl = process.env.VUE_APP_APIURL;
      const response = await fetch(
        `${apiUrl}/api/travels?filter=${rootState.user.shortname}`,
        {
          method: "GET",
          headers: myHeaders,
        }
      );
      const result = await response.json();
      commit(types.TRAVEL_LOAD_DATA, {
        travelId: rootState.user.shortName,
        data: result,
      });
      commit("setLoadData");
    },
    /**
     * ACTION FUNCTION: call http request for list
     * @method startLoad
     */
    async startLoadALL({ commit, rootState }) {
      commit("setLoadData");
      const myHeaders = new Headers();

      myHeaders.append("Authorization", rootState.user.token);
      myHeaders.append("X-API-KEY", process.env.VUE_APP_APIKEY);
      const apiUrl = process.env.VUE_APP_APIURL;

      const response = await fetch(
        `${apiUrl}/api/travels/ALL`,
        {
          method: "GET",
          headers: myHeaders,
        }
      );
      const result = await response.json();
      commit(types.TRAVEL_LOAD_DATA_TABLE, {
        TableTravels: result
      });
      commit("setLoadData");
    },
    /**
     * ACTION FUNCTION: call http request for next list data travels
     * @method startUpdate
     */
    async startUpdate({ commit, state, rootState }) {
      const apiUrl = process.env.VUE_APP_APIURL;
      const myHeaders = new Headers();
      myHeaders.append("Authorization", rootState.user.token);
      myHeaders.append("X-API-KEY", process.env.VUE_APP_APIKEY);
      const response = await fetch(
        `${apiUrl}/api/travels/${rootState.user.uid}/${state.dataIndexNext}`,
        {
          method: "GET",
          headers: myHeaders,
        }
      );
      const result = await response.json();
      commit(types.TRAVEL_UPDATE_DATA, result);
    },
    /**
     * ACTION FUNCTION: call http request for list data history
     * @method startLoadHistory
     */
    async startLoadHistory({ commit, rootState }) {
      console.log("inici");
      commit("setLoadDataHistory");
      const apiUrl = process.env.VUE_APP_APIURL;
      const uid = await rootState.user.uid;
      const myHeaders = new Headers();
      myHeaders.append("Authorization", rootState.user.token);
      myHeaders.append("X-API-KEY", process.env.VUE_APP_APIKEY);
      const response = await fetch(`${apiUrl}/api/histories/${uid}`, {
        method: "GET",
        headers: myHeaders,
      });
      const result = await response.json();
      commit(types.TRAVEL_LOAD_HISTORY_DATA, result);
      commit("setLoadDataHistory");
    },
    /**
     * ACTION FUNCTION: call http request for next list data hisotry
     * @method startUpdateHistory
     */
    async startUpdateHistory({ commit, rootState }) {
      const apiUrl = process.env.VUE_APP_APIURL;
      const myHeaders = new Headers();
      myHeaders.append("Authorization", rootState.user.token);
      myHeaders.append("X-API-KEY", process.env.VUE_APP_APIKEY);
      const response = await fetch(
        `${apiUrl}/api/histories/${rootState.user.uid}/update`,
        {
          method: "GET",
          headers: myHeaders,
        }
      );
      const result = await response.json();
      commit(types.TRAVEL_UPDATE_HISTORY_DATA, result);
    },
    /**
     * example data
     */
    async setData({ commit }) {
      const data = await new Promise((resolve) =>
        setTimeout(() => {
          var moreItems = [
            {
              id:"S22\r\nS04B\r\nN09B",
              vehicle: {
                model: "WU YANG FORTUNE\r\nSCARLET ROBIN\r\nGASCHEM BELUGA",
                name: "18",
                type: "car",
              },
              driver: {
                fullName: "pedro gonzales",
                shortName: "CHONG / LANDE / ALVAR",
                thumbnail: "assets/images/captain-img.png",
                title: "Piloto",
                traveled: 800,
                phone: 3422342,
              },
              departure: {
                location: "Mine Dock",
                time: "6:40pm",
                dateTime: "2023-05-25T09:29:44"
              },
              arrival: {
                location: "12456Diablo",
                time: "6:50pm",
                dateTime: "2023-05-26T09:29:44"
              },
              statustime: "6.37pm",
              status: "On Time",
            },
            {
              id: "N1",
              vehicle: {
                model: "ZIM SAVANAH\r\nSSILVER HERBA",
                name: "24",
                type: "ship",
                //type: "car",
              },
              driver: {
                fullName: "Armando Martinez",
                shortName: "AROSE / GOMED",
                thumbnail: "assets/images/captain-img.png",
                title: "Piloto",
                traveled: 800,
                phone: 3422342,
              },
              departure: {
                location: "Diablo",
                time: "6:50pm",
                dateTime: "2023-05-25T09:29:44"
              },
              arrival: {
                location: "Fort Knox",
                time: "7:10pm",
                dateTime: "2023-05-26T09:29:44"
              },
              statustime: "En espera",
              status: "Pending",
            },
            {
              id: "NNN03",
              vehicle: {
                model: "PATRICIA OF ARGYLL",
                name: "2511",
                type: "car",
              },
              driver: {
                fullName: "edgar paulk",
                shortName: "AROSE",
                thumbnail: "assets/images/captain-img.png",
                title: "Piloto",
                traveled: 800,
                phone: 3422342,
              },
              departure: {
                location: "David",
                time: "6:50pm",
                dateTime: "2023-05-25T09:29:44"
              },
              arrival: {
                location: "Fort Knox",
                time: "7:10pm",
                dateTime: "2023-05-26T09:29:44"
              },
              statustime: "En espera",
              status: "Pending",
            },
          ];
          for (let index = 2; index < 105; index++) {
            moreItems.push({
              id: "N"+index,
              vehicle: {
                model: "Ford Focus",
                name: "" + index,
                type: index == 2 ? "tug" : "Airplane",
              },
              driver: {
                fullName: "Alexander Jimenez",
                shortName: "AJimenez",
                thumbnail: "assets/images/captain-img.png",
                title: "Conductor",
                traveled: 800,
                phone: 3422342,
              },
              departure: {
                location: "Mine Dock",
                time: "6:40pm",
                dateTime: "2023-05-25T09:29:44"
              },
              arrival: {
                location: "Mine ROOM",
                time: "6:50pm",
                dateTime: "2023-05-26T09:29:44"
              },
              statustime: "6.37pm",
              status: "On Time",
            });
          }
          resolve(moreItems);
        }, 2000)
      );

      commit(types.TRAVEL_LOAD_DATA, {
        travelId: 34534534,
        data,
      });
      commit(types.TRAVEL_LOAD_DATA_TABLE, {
        TableTravels : data
      });
    },
    /**
     * Clear DAta
     * @method clearData
     */
    async clearData({ commit }) {
      commit(types.TRAVEL_LOAD_DATA, {
        travelId: null,
        data: [],
      });
    },
    /**
     * Clear Data table
     * @method clearDataTable
     */
    async clearDataTable({ commit }) {
      commit(types.TRAVEL_LOAD_DATA_TABLE, {
        TableTravels :[]
      });
    },
    /**
     * ACTION FUNCTION: call TRAVEL_VEHICLE_UPDATE_DATA and add attribute vehicle object
     * @method setVehicleData
     */
    setVehicleData({ commit }, payload) {
      commit(types.TRAVEL_VEHICLE_UPDATE_DATA, payload);
    },
  },
  getters: {
    /**
     * GETTERS: Get data travel after filter fullName and filter (search)
     * @method getTravels
     * @param {string} fullName
     * @param {string} filter
     * @returns array
     */
    getTravels: (state) => (filter) => {
      return state.data.length == 0
        ? []
        : state.data
          .filter((travel) => travel.id != " ")
          .filter((travel) => {
            const name =
              travel.vehicle.name != null
                ? travel.vehicle.name
                  .toLowerCase()
                  .includes(filter.toLowerCase())
                : false;
            const model =
                travel.vehicle.model != null
                  ? travel.vehicle.model
                    .toLowerCase()
                    .includes(filter.toLowerCase())
                  : false;
            const shortName =
              travel.driver.shortName != null
                ? travel.driver.shortName
                  .toLowerCase()
                  .includes(filter.toLowerCase())
                : false;
            const fullName =
              travel.driver.fullName != null
                ? travel.driver.fullName
                  .toLowerCase()
                  .includes(filter.toLowerCase())
                : false;
            const title =
              travel.driver.title != null
                ? travel.driver.title
                  .toLowerCase()
                  .includes(filter.toLowerCase())
                : false;
            const locationD =
              travel.departure.location != null
                ? travel.departure.location
                  .toLowerCase()
                  .includes(filter.toLowerCase())
                : false;
            const locationA =
              travel.arrival.location != null
                ? travel.arrival.location
                  .toLowerCase()
                  .includes(filter.toLowerCase())
                : false;
            const status =
              travel.status != null
                ? travel.status.toLowerCase().includes(filter.toLowerCase())
                : false;
                //console.log(travel)
            return status || name || shortName || locationD || locationA || fullName || model || title;
          });
    },
        /**
     * GETTERS: Get data travel after filters (search)
     * @method getTravels
     * @param {Object} filters - {id, vehicle, model, driver, shortName, title, departure, arrival, Status}
     * @returns array
     */
        getTravelsFilter: (state) => (filters) => {
          if(state.TableTravels.length == 0){
            return [];
          }

          let resp = state.TableTravels.filter((travel) => travel.id != " ");

          if(filters.id != null && filters.id != "") {
            resp = resp.filter((travel) =>{
              return travel.id != null
                ? travel.id
                  .toLowerCase()
                  .includes(filters.id.toLowerCase())
                : false;
            })
          }

          if(filters.vehicle != null && filters.vehicle != "") {
            resp = resp.filter((travel) =>{
              return travel.vehicle.name != null
                ? travel.vehicle.name
                  .toLowerCase()
                  .includes(filters.vehicle.toLowerCase())
                : false;
            })
          }

          if(filters.model != null && filters.model.length > 0) {
            resp = resp.filter((travel) =>{
              return travel.vehicle.model != null
                ?  filters.model.findIndex(item => travel.vehicle.model.toLowerCase().includes(item.toLowerCase())) != -1  
                : false;
            })
          }

          if(filters.driver != null && filters.driver != ""){
            resp = resp.filter((travel) =>{
              return  travel.driver.fullName != null
              ? travel.driver.fullName
                .toLowerCase()
                .includes(filters.driver.toLowerCase())
              : false;
            }) 
          }

          if(filters.shortName != null && filters.shortName != ""){
            resp = resp.filter((travel) =>{
              return travel.driver.shortName != null
              ? travel.driver.shortName
                .toLowerCase()
                .includes(filters.shortName.toLowerCase())
              : false;
            }) 
          }

          if(filters.title != null && filters.title.length > 0){
            resp = resp.filter((travel) =>{
              return travel.driver.title != null
              ? filters.title.findIndex(item => travel.driver.title.toLowerCase().includes(item.toLowerCase())) != -1 
              : false;
            }) 
          }

          if(filters.departure != null && filters.departure.length > 0){
            resp = resp.filter((travel) =>{
              return  travel.departure.location != null
              ?  filters.departure.findIndex(item => travel.departure.location.toLowerCase().includes(item.toLowerCase())) != -1 
              : false;
            }) 
          }

          if(filters.arrival != null && filters.arrival.length > 0){
            resp = resp.filter((travel) =>{
              return  travel.arrival.location != null
              ? filters.arrival.findIndex(item => travel.arrival.location.toLowerCase().includes(item.toLowerCase())) != -1  
              : false;
            }) 
          }

          if(filters.Status != null && filters.Status != ""){
            resp = resp.filter((travel) =>{
              return  travel.status != null
              ? travel.status
                .toLowerCase()
                .includes(filters.Status.toLowerCase())
              : false;
            }) 
          }

          return resp;
        },
        /*
        * GETTERS: getfilters from data
        */
       getFilters: (state)=>()=>{
        let ListForFilters={
          models:[],
          titles:[],
          departures:[],
          arrivals:[]
        };
        let modelsTemp =[];
        let titlesTemp =[];
        let departureTemp =[];
        let arrivalTemp =[];
        state.TableTravels.forEach(i => {
          if(i.vehicle.model!=" " && i.vehicle.model!="")
          modelsTemp.push(i.vehicle.model);
          if(i.driver.title!=" " && i.driver.title!="")
          titlesTemp.push(i.driver.title);
          if(i.departure.location!=" " && i.departure.location!="")
          departureTemp.push(i.departure.location);
          if(i.arrival.location!=" " && i.arrival.location!="")
          arrivalTemp.push(i.arrival.location);
        });
        ListForFilters.models = Array.from(new Set(modelsTemp));
        ListForFilters.titles = Array.from(new Set(titlesTemp));
        ListForFilters.departures = Array.from(new Set(departureTemp));
        ListForFilters.arrivals = Array.from(new Set(arrivalTemp));
        return ListForFilters;
        },
    /**
     * GETTERS: get history list  after filter searchWorld
     * @method getHistories
     * @param {string} searchWord
     * @returns array
     */
    getHistories: (state) => (searchWord) => {
      const items = [];
      state.histories.map((history) => {
        items.push({
          label: history.label,
          data: history.data
            .filter(
              // eslint-disable-next-line
              (el) => el.fulltext.toLowerCase().includes(searchWord)
            )
            .sort((a, b) => {
              const dateA = new Date(a.date);
              const dateB = new Date(b.date);
              return dateB.getTime() - dateA.getTime();
            }),
        });
        return history;
      });
      return items;
    },
    /**
     * GETTERS: load data for attribute histories
     * @method loadingHistories
     * @returns {Boolean} false - Default
     */
    loadingHistories: (state) => state.loadDataHistory,
    /**
     * GETTERS: load data for attribute data.
     * @method loadingHome
     * @returns {Boolean} false - Default
     */
    loadingHome: (state) => state.loadData,
  },
};

export default travels;
