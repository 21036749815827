<template>
  <ion-app>
    <SecondaryMenu
      :username="username"
      :isLogin="isLogin"
      :listMenu="listMenu"
      :version="version"
    >
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </SecondaryMenu>
  </ion-app>
</template>

<script>
import { IonApp } from "@ionic/vue";
import { defineComponent, onMounted } from "vue";
import { IonRouterOutlet } from "@ionic/vue";
import SecondaryMenu from "./components/SecondaryMenu.vue";
import { useStore } from "vuex";
//import { informationCircleOutline, megaphoneOutline } from "ionicons/icons";

/**
 * @displayName App
 * @author Diogenes Ardines, Ricardo POrtillo
 * @supplier ACP, Logicstudio
 * @version 1.5.0
 */
export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    SecondaryMenu,
  },
  created() {
    document.addEventListener("swUpdated", this.appUpdateUI, { once: true });
  },
  data() {
    return {
      /**
       * Version of app
       * @type String
       */
      version: process.env.VUE_APP_UIVERSION,
    };
  },
  setup() {
    const store = useStore();
    const isLogin = store.getters["user/isLogin"];
    const username = store.state.user.userId;
    const listMenu = [
      /*{
        title: "Programa de lealtad",
        subtitle: "",
        url: "/pages/Loyalty",
        urlIcon: "assets/icon/mira-ship-icon.svg",
        iosIcon: null,
        mdIcon: null,
        disabled: true,
      },
      {
        title: "Alertas",
        subtitle: null,
        url: "/pages/Alert",
        urlIcon: null,
        iosIcon: megaphoneOutline,
        mdIcon: megaphoneOutline,
        disabled: true,
      },
      {
        title: "Ayuda",
        subtitle: null,
        url: "/pages/Help",
        urlIcon: null,
        iosIcon: informationCircleOutline,
        mdIcon: informationCircleOutline,
        disabled: true,
      },
      {
        title: "Configuración y ajuste",
        subtitle: null,
        url: "/pages/Settings",
        urlIcon: "assets/icon/mira-setting-icon.svg",
        iosIcon: null,
        mdIcon: null,
        disabled: true,
      },*/
    ];
    onMounted(() => {
      store.dispatch("app/setCheckApiConnection");
    });
    return {
      /**
       * userId for user login
       * @type String
       */
      username,
      /**
       * GETTERS determinate user login
       * @type Boolean
       */
      isLogin,
      /**
       * List menu option
       * @type object
       */
      listMenu,
    };
  },
});
</script>

<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>
