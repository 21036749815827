<template>
  <!-- eslint-disable -->
  <ion-split-pane content-id="main-content" when="false">
    <ion-menu
      menu-id="secondary"
      content-id="main-content"
      v-show="isTabletAndLogin"
      :side="isTablet"
      type="overlay"
      class="custom"
    >
      <ion-content>
        <ion-list>
          <ion-list-header>
            <ion-button class="ion-hide-md-up" @click="handleCloseMenu">
              <ion-icon
                slot="start"
                :ios="closeOutline"
                :md="closeOutline"
                color="dark"
              ></ion-icon>
            </ion-button>
            <ion-title class="ion-text-center">
              <img src="assets/images/CP_logo_safezone-2.svg" />
            </ion-title>
          </ion-list-header>
          <ion-label class="ion-text-center">
            <h6>ver. {{ version }}</h6>
          </ion-label>
          <ion-grid>
            <ion-row class="userLabel">
              <ion-col size-md="3" size-lg="2">
                <ion-img
                  class="userImg"
                  v-show="userImg"
                  :src="userImg"
                ></ion-img>
                <ion-img
                  class="userImg-default"
                  v-show="!userImg"
                  src="assets/images/User-Profile-Dummy.svg"
                ></ion-img>
              </ion-col>
              <ion-col class="ion-text-left">
                <h4>
                  <strong>{{ username }}</strong>
                </h4>
                <!-- <p>{{ position }}</p> -->
              </ion-col>
            </ion-row>
          </ion-grid>
          <br />
          <ion-item
            v-for="(p, i) in listMenu"
            @click="handleCloseMenu"
            router-direction="front"
            :router-link="p.url"
            lines="none"
            detail="false"
            class="hydrated"
            :key="i"
            :disabled="p.disabled"
          >
            <ion-icon
              v-if="!p.urlIcon"
              color="acp"
              slot="start"
              :ios="p.iosIcon"
              :md="p.mdIcon"
            ></ion-icon>
            <ion-icon
              v-if="p.urlIcon"
              color="acp"
              slot="start"
              :src="p.urlIcon"
            ></ion-icon>
            <ion-label>
              {{ p.title }}
              <div v-if="p.subtitle">{{ p.subtitle }}</div>
            </ion-label>
          </ion-item>
        </ion-list>
        <br /><br />
        <div class="ion-text-center">
          <ion-button disabled="true" color="danger">
            Asistencia inmediata
            <ion-icon slot="end" :icon="warningOutline"></ion-icon>
          </ion-button>
        </div>
      </ion-content>
    </ion-menu>
    <!-- SLOT DEFAULT -->
    <slot></slot>
  </ion-split-pane>
</template>

<script>
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonSplitPane,
  menuController,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonTitle,
  IonButton,
} from "@ionic/vue";
import { isPlatform } from "@ionic/core";
import { closeOutline, warningOutline } from "ionicons/icons";
import { computed } from "vue";

/**
 * Componente que permite crear un menú para ionic
 * @displayName Menu secundario
 * @author Ricardo Portillo
 * @supplier Logicstudio
 * @version 0.0.1
 *
 * note: to use it preferable to place an ionic router
 *
 */
export default {
  props: {
    /**
     * Show username login in app
     */
    username: {
      type: String,
      default: "",
    },
    /** show group position */
    position: {
      type: String,
      default: "Práctico-Lorem haftem consectutit",
    },
    /**
     * url of picture  of user login.
     */
    userImg: {
      type: String,
      default: "",
    },
    /**
     * Boolean property for show menu or not
     */
    isLogin: {
      type: Boolean,
      default: false,
    },
    /**
     * List menu.
     */
    listMenu: {
      type: Array,
      default: () => [],
    },
    /**
     * app version
     */
    version: {
      type: String,
      default: "",
    },
  },
  components: {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonSplitPane,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonTitle,
    IonButton,
  },
  setup(props) {
    const labels = [
      "Family",
      "Friends",
      "Notes",
      "Work",
      "Travel",
      "Reminders",
    ];

    //method

    /**
     * Method close menu
     * @method handleCloseMenu
     * @returns  void
     */
    const handleCloseMenu = () => {
      menuController.close("secondary");
    };

    const isTablet = computed(() => {
      return isPlatform("tablet") || isPlatform("desktop") ? "start" : "end";
    });

    const isTabletAndLogin = computed(() => {
      return (isPlatform("tablet") || isPlatform("desktop")) && !props.isLogin
        ? false
        : true;
    });
    return {
      /**
       * name of icon
       * @type icon
       */
      closeOutline,
      /**
       * list label
       * @type array
       */
      labels,
      handleCloseMenu,
      /**
       * name of icon
       * @type icon
       */
      warningOutline,
      /**
       * Computed function for app is view in tablet
       * @type boolean
       */
      isTablet,
      /**
       * computed function for show menu in table  that login
       * @type boolean
       */
      isTabletAndLogin,
      // sSelected: (url: string) => url === route.path ? 'selected' : ''
    };
  },
};
</script>

<style scoped>
ion-label {
  color: #535252 !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
}
ion-label div {
  color: var(--ion-color-secondary);
  font-size: 14px !important;
}
ion-title img {
  width: 200px;
  height: 35px;
}
.userLabel h4 {
  color: var(--ion-color-acp);
  padding-bottom: 0px;
  margin-bottom: 5px !important;
}
.userLabel p {
  margin-top: 0;
}

.userImg::part(image) {
  width: 90px;
  height: 90px;
  border-radius: 100%;
}
.userImg::part(image),
.userImg-default::part(image) {
  max-width: 65px;
}
@media (max-width: 768px) {
  .custom {
    --width: 45vh;
  }
  ion-list-header .ion-text-center {
    text-align: left !important;
  }
}
@media (min-width: 769px) {
  .userLabel h4 {
    font-size: 16px;
  }
  ion-img.userImg::part(image),
  .userImg-default::part(image) {
    max-width: 45px;
  }
  ion-title {
    padding-inline: 50px;
  }
}
</style>
