// import types from "../types";

import types from "../../types";

const init = {
  userId: null,
  uid: null,
  username: "",
  position: "",
  shortname: "",
  images: "",
  token: "",
};

/**
 * user store
 * @displayName Travels
 * @author Ricardo Portillo
 * @supplier Logicstudio
 * @version 0.0.1
 */
const user = {
  namespaced: true,
  state() {
    return {
      userId: null,
      uid: null,
      images: "",
      position: "",
      shortname: "",
      token: "",
      username: "",
    };
  },
  mutations: {
    /**
     * MUTATION FUNCTION: set attribute state
     * @method USER_SET_USER
     * @param {object} state - attribute objecte
     * @param {object} payload all atribute for state
     */
    [types.USER_SET_USER](state, payload) {
      // eslint-disable-next-line
      state.uid = 0;
      state.username = payload.username;
      state.position = payload.position;
      state.images = payload.images;
      state.shortname = payload.shortname;
    },
    /**
     * MUTATION FUNCTION: Set user id attribute
     * @method USER_SET_USERID
     * @param {object} state - attribute objecte
     * @param {string} payload
     */
    [types.USER_SET_USERID](state, payload) {
      state.userId = payload;
    },
    /**
     * MUTATION FUNCTION: init all attribute
     * @method USER_DEL_USER
     * @param {object} state - attribute objecte
     */
    [types.USER_DEL_USER](state) {
      // eslint-disable-next-line
      state.userId = init.userId;
      state.uid = init.uid;
      state.username = init.username;
      state.position = init.position;
      state.images = init.images;
      state.token = init.token;
      state.shortname = init.shortname;
    },
    /**
     * MUTATION FUNCTION: set user tokken
     * @method USER_SET_TOKEN
     * @param {object} state - attribute objecte
     * @param {String} payload
     */
    [types.USER_SET_TOKEN](state, payload) {
      // eslint-disable-next-line
      state.token = payload;
    },
  },
  actions: {
    /**
     * ACTIONS: http request to call api/user for user data.
     * @method setUser
     * @param {object} payload - {userId, token}
     */
    async setUser({ commit }, { userId, token }) {
      const user = localStorage.getItem("userData");
      const current_update = Date.now();

      let result;

      if (
        user == null ||
        JSON.parse(user).next_update === undefined ||
        JSON.parse(user).next_update < current_update
      ) {



        const apiUrl = process.env.VUE_APP_APIURL;

        const myHeaders = new Headers();
        myHeaders.append("Authorization", token);
        myHeaders.append("X-API-KEY", process.env.VUE_APP_APIKEY);

        const response = await fetch(
          `${apiUrl}/api/users/${userId.toLowerCase()}`,
          {
            method: "GET",
            headers: myHeaders,
          }
        );

        result = await response.json();
        const d = new Date();
        const next_update = d.setTime(d.getTime() + 0.1 * 60 * 60 * 1000);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...result, current_update, next_update })
        );
      } else {


        result = JSON.parse(user);

      }

      commit(types.USER_SET_USERID, userId);
      commit(types.USER_SET_USER, result);
      //dispatch("setTokken");
    },
    /**
     * ACTIONS:  logout of aplication delete user data for localstorage and store.
     * @method logout
     */
    async logout({ commit }) {
      localStorage.clear();
      commit(types.USER_DEL_USER);
    },
    /**
     * ACTIONS: set Tokken from get session storage esriJSAPIOAuth
     * @method setTokken
     */
    setTokken({ commit }) {
      const esriJSAPIOAuth = JSON.parse(
        sessionStorage.getItem("esriJSAPIOAuth")
      );
      const token = esriJSAPIOAuth["/"][process.env.VUE_APP_PORTALURL].token;
      commit(types.USER_SET_TOKEN, token);
    },
  },
  getters: {
    /**
     * Check user status.
     * @method isLogin
     * @param {object} state attribute object
     * @returns {Boolean} false
     */
    isLogin: (state) =>
      state.userId == undefined || state.userId == null ? false : true,
    getShortname: (state) => state.shortname,
  },
};

export default user;
