import types from "../../types";

/**
 * App store
 * @displayName App
 * @author Ricardo Portillo
 * @supplier Logicstudio
 * @version 0.0.1
 */
const app = {
  namespaced: true,
  state() {
    return {
      connection: navigator.onLine,
      connectionApi: false,
      colors: {},
    };
  },
  mutations: {
    /**
     * MUTATION FUNCTION: set conection value
     * @method APP_SET_CONNECTION
     * @param {object} state state attribute
     */
    [types.APP_SET_CONNECTION](state) {
      // eslint-disable-next-line
      state.connection = navigator.onLine;
    },
    /**
     * MUTATION FUNCTION: set conection API value
     * @method APP_SET_CONNECTION_API
     * @param {object} state state attribute
     * @param {boolean} payload
     */
    [types.APP_SET_CONNECTION_API](state, payload) {
      // eslint-disable-next-line
      state.connectionApi = payload;
    },
    /**
     * MUTATION FUNCTION: Set color from API
     * @method APP_SET_COLORS
     * @param {object} state state attribute
     * @param {object} payload
     */
    [types.APP_SET_COLORS](state, payload) {
      // eslint-disable-next-line
      state.colors = payload;
    },
    test(state) {
      // eslint-disable-next-line
      state.connection = false;
    },
  },
  actions: {
    /**
     * ACTION FUNCTION: Set connection
     * @method setConnection
     */
    setConnection({ commit }) {
      commit(types.APP_SET_CONNECTION);
    },
    /**
     * ACTION FUNCTION: Async function call http request to health from API
     * @method setCheckApiConnection
     */
    async setCheckApiConnection({ commit }) {
      const apiUrl = `${process.env.VUE_APP_APIURL}/health`;
      const result = await fetch(apiUrl);
      const data = await result.text();
      commit(types.APP_SET_CONNECTION_API, data === "Healthy" ? true : false);
    },
    /**
     * ACTION FUNCTION: Async function call http request to colors from API
     * @method setColors
     */
    async setColors({ commit, rootState }) {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", rootState.user.token);
      myHeaders.append("X-API-KEY", process.env.VUE_APP_APIKEY);
      const apiUrl = process.env.VUE_APP_APIURL;
      const response = await fetch(`${apiUrl}/api/colors`, {
        method: "GET",
        headers: myHeaders,
      });
      const result = await response.json();
      commit(types.APP_SET_COLORS, result);
    },
  },
  getters: {
    /**
     * GETTERS: Get color of color list
     * @method getColor
     * @param {int} colorId
     * @returns color name
     */
    getColor: (state) => (colorId) => {
      return state.colors[colorId];
    },
  },
};

export default app;
