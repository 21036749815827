import { createRouter, createWebHistory } from "@ionic/vue-router";
// import store from "@/store";
// import useESRILogin from "@/hooks/useESRILogin";
import { registerGuard } from "./guard";

/**
 * Config router whith components
 */
export const routes = [
  {
    path: "/",
    redirect: "/pages/intro",
  },
  {
    path: "/pages/intro",
    component: () => import("../pages/IntroPage.vue"),
  },
  {
    path: "/pages",
    component: () => import("../pages/MainPage.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "Home",
        redirect: "/pages/Home",
        meta: { requiresAuth: true },
      },
      {
        path: "/pages/JobListPage",
        component: () => import("../pages/JobListPage.vue"),
      },
      {
        path: "/pages/History",
        component: () => import("../pages/HistoryPage.vue"),
      },
      {
        path: "/pages/Home",
        component: () => import("../pages/HomePage.vue"),
      },
      {
        path: "/pages/Travels",
        component: () => import("../pages/TravelsPage.vue"),
        meta:{AutenticateGroup: ""+process.env.VUE_APP_IDGROUPTRAVELPAGE }
      },
      {
        path: "/pages/Modal",
        component: () => import("../pages/ModalTest.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../pages/NotFoundPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach(async (to) => {
//   const { checkLogin, setRegisterToken, logout } = useESRILogin();
//   /** Check user login */
//   try {
//     const result = await checkLogin();
//     if (
//       (result.userId != "undefined" ||
//         result.name != "identity-manager:not-authenticated") &&
//       !store.getters["user/isLogin"]
//     ) {
//       if (result.expires > Date.now()) {
//         await store.dispatch("user/setUser", {
//           userId: result.userId,
//           token: result.token,
//         });
//         if (to.path == "/pages/intro") {
//           setRegisterToken(result);
//           return {
//             path: "/pages/",
//           };
//         }
//       } else {
//         logout();
//         await store.dispatch("user/logout");
//       }
//     }
//   } catch {
//     console.log("Not access");
//   }
//   // END check user login.

//   if (to.meta.requiresAuth && !store.getters["user/isLogin"]) {
//     // this route requires auth, check if logged in
//     // if not, redirect to login page.
//     return {
//       path: "/pages/intro",
//     };
//   }
// });

registerGuard(router);
export default router;
